import {post, download} from "./index.js";

export const ReqInvoiceExpressGetListByPage = (data) => {
    return post("/apis/invoice-express/getListByPage", data).then(res => res);
};

export const ReqInvoiceExpressSetExpressPosted = (data) => {
    return post("/apis/invoice-express/setExpressPosted", data).then(res => res);
};

export const ReqInvoiceExpressExport = (data) => {
    return download("/apis/invoice-express/export", data).then(res => res);
};
